import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerPhotos from '../components/BannerPhotos'

import charlesAndRicky from '../assets/images/oldwebsite_bropics/charlesAndRicky.jpg'
import mexico from '../assets/images/mainpage/brothersincancun.jpg'
import pledges from '../assets/images/mainpage/gammaphi.jpg'
import fallFormal from '../assets/images/mainpage/fall2023.jpg'
import sophFormal from '../assets/images/mainpage/formal2024v2.jpg'
import sophRetreat from '../assets/images/mainpage/pledgeretreat2023.jpg'
import retreatPic1 from '../assets/images/mainpage/springretreat2024.jpg'
import sandLetters from '../assets/images/sandLetters.jpg'
import marathon from '../assets/images/marathon.jpg'
import pledgeSkit from '../assets/images/mainpage/candidateskit.png'
import ringdelivery from '../assets/images/mainpage/ringdelivery2024.jpg'

const Photos = (props) => (
    <Layout>
        <Helmet>
            <title>Photo Gallery</title>
            <meta name="description" content="Photos Page" />
        </Helmet>

        <BannerPhotos />
                <div id="main">
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${ringdelivery})`}}>
                            <header className="major">
                                <h3>Sophomore Ring Delivery</h3>
                            </header>
                        </article>

                        <article style={{backgroundImage: `url(${mexico})`}}>
                            <header className="major">
                                <h3>Brothers in Mexico</h3>
                            </header>
                        </article>

                        <article style={{backgroundImage: `url(${fallFormal})`}}>
                            <header className="major">
                                <h3>Brothers and Friends</h3>
                            </header>
                        </article>

                        <article style={{backgroundImage: `url(${pledges})`}}>
                            <header className="major">
                                <h3>Pledges</h3>
                            </header>
                        </article>

                        <article style={{backgroundImage: `url(${sophRetreat})`}}>
                            <header className="major">
                                <h3>Pledge Retreat</h3>
                            </header>
                        </article>

                        <article style={{backgroundImage: `url(${sophFormal})`}}>
                            <header className="major">
                                <h3>Spring 2024 Formal</h3>
                            </header>
                        </article>                        

                        <article style={{backgroundImage: `url(${retreatPic1})`}}>
                            <header className="major">
                                <h3>Spring 2024 Retreat</h3>
                            </header>
                        </article>                        

                        <article style={{backgroundImage: `url(${sandLetters})`}}>
                            <header className="major">
                                <h3>Rush Sigma Nu</h3>
                            </header>
                        </article>                        

                        <article style={{backgroundImage: `url(${marathon})`}}>
                            <header className="major">
                                <h3>Marathon Volunteering!</h3>
                            </header>
                        </article>   

                        <article style={{backgroundImage: `url(${pledgeSkit})`}}>
                            <header className="major">
                                <h3>Pledges Present a Skit</h3>
                            </header>
                        </article>
                    </section>
                    </div>
    </Layout>
)

export default Photos
